import PageHeader from "../components/common/PageHeader";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import ApiService from "../api/ApiService";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import moment from 'moment';
import YatrasCardWrap from '../components/yatras/YatrasCardWrap.jsx';
import Fancybox from '../helpers/Fancybox';
import parse from 'html-react-parser';
import fileDownload from "js-file-download";

const EventDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { user } = useContext(AppContext);

  const {
    reset,
  } = useForm();


  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  const [events, setevents] = useState([])
  const fetchevents = async () => {
    try {
      const res = await ApiService.fetchEvents()
      //  console.log(res)
      setevents(res)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownload = (url, fileName) => {
    // console.log('URL', url);
    // console.log('Filename', fileName);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  useEffect(() => {
    fetchevents()
  }, [])


  return (
    <>
      <MetaData title="Events details - ISKCON of Bhiwandi" />
      <PageHeader title="Events details" imgSrc="/images/page-header/03.webp" />


      <section className="vrindavan-card">
        <div className="container">
          {

            !(events.length > 0) ? <YatrasCardWrap /> : events.map((event) => (
              <>
                {

                  event.slug === id ?
                    <div className="vrindavan-wrap mt-4">
                      <div className="row">
                        <div className="col-lg-5">
                          <figure className="yatra-fig">
                            <img src={`${process.env.REACT_APP_S3_URL}/uploads/event/${event?.image}`} alt="" />
                          </figure>
                        </div>
                        <div className="col-lg-7">
                          <div className="heading my-auto">
                            <div className="mb-md-2 mt-md-0 mt-4">
                              <h2 className="head-2">{event?.title} </h2>
                              {/* <h2 className="head-1">{Yatra?.title}</h2> */}
                            </div>
                            <div className="date-div mb-md-2">
                              <figure className="date-up">
                                <img src="/images/yatras/up.svg" alt="" />
                              </figure>
                              <h5>{
                                moment(event.startDate).format('dddd, D MMMM YYYY')
                              }</h5>
                              <figure className="date-down">
                                <img src="/images/yatras/down.svg" alt="" />
                              </figure>
                            </div>
                            <div>
                              <p>
                                {parse(event?.description)}
                              </p>

                              
                              {/* <div class="d-flex">
                                <button  onClick={() => handleDownload(`${process.env.REACT_APP_S3_URL}/uploads/event/${event?.eventPdf}`, `${event?.title}.pdf`)} class="custom-btn-cls box-hover">Download PDF</button>
                              </div> */}
                            

                            </div>

                          </div>

                          

                          </div>
                        </div>
                      </div>

                      : ''
}

                    </>
))}

              </div >
</section>


      <section className="yatras-wrap">
        <div className="container">
          <div className="heading">
            <h2 className="head-1">Gallery</h2>
          </div>
          <div className="yatras-wrap">
            <div className="row my-5">
              {events?.map((event) => (
                event.slug === id && event?.eventImage_a ?
                  event.eventImage_a.map((image, index) => (

                    <div key={index} className="col-xl-4 col-lg-6 col-md-6 mb-5">
                      <div className="yatra-card">
                        <div className="yatra-top">
                          <Fancybox>
                            <a data-fancybox="gallery" href={`${process.env.REACT_APP_S3_URL}/uploads/event/${image}`} data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/event/${image}`}  >
                              <figure style={{ border: "6px solid #F3B01B", padding: "5px" }}>
                                <img src={`${process.env.REACT_APP_S3_URL}/uploads/event/${image}`} alt="" />
                              </figure>
                            </a>
                          </Fancybox>
                        </div>
                      </div>
                    </div>
                  ))
                  : ''
              ))}
            </div>
          </div>
        </div>
      </section>

      <GoToTop />
    </>
  );
};



export default EventDetails;
